import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const CabaretInfosNavigation = ({ noTitle }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    // Attacher l'événement de redimensionnement lors du montage
    window.addEventListener('resize', handleResize)

    // Détacher l'événement de redimensionnement lors du démontage
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
        lesPrixImage: file(relativePath: { eq: "cabaret/infos/lesprix.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        reglementsImage: file(relativePath: { eq: "cabaret/infos/reglements.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        inscriptionImage: file(relativePath: { eq: "cabaret/infos/inscription.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        hebergementsImage: file(relativePath: { eq: "cabaret/infos/hebergements.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        porteParoleImage: file(relativePath: { eq: "cabaret/infos/porteparole.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        partenairesImage: file(relativePath: { eq: "cabaret/infos/partenaires.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        gagnantsImage: file(relativePath: { eq: "cabaret/infos/gagnants.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
    }
  `)

  const lesPrixImage = getImage(data.lesPrixImage)
  const reglementsImage = getImage(data.reglementsImage)
  const inscriptionImage = getImage(data.inscriptionImage)
  const hebergementsImage = getImage(data.reglementsImage)
  const porteParoleImage = getImage(data.porteParoleImage)
  const partenairesImage = getImage(data.partenairesImage)
  const gagnantsImage = getImage(data.gagnantsImage)

  let index = 0
  const getNextColor = () => {
    const bgArray = isMobile ? ['bg-purple-cabaret', 'bg-red-cabaret', 'bg-red-cabaret', 'bg-purple-cabaret'] : ['bg-purple-cabaret', 'bg-red-cabaret']
    return bgArray[(index++) % bgArray.length]
  }

  const getNavButton = (title, internalLink, imageSrc, imageAlt, longText = false) => {
    return (
            <li className={`w-[150px] h-[150px] rounded-3xl m-[10px] ${getNextColor()} hover:opacity-80`}>
                <a rel="noopener noreferrer" href={internalLink}>
                    <div className="m-[15px] !text-center !align-center">
                        <div className="mt-[23px] mb-[8px]">
                            <GatsbyImage image={imageSrc} alt={imageAlt} className={`w-[60px] ${longText ? 'h-[40px]' : 'h-[65px]'}`} imgStyle={{ objectFit: 'contain' }}/>
                        </div>
                        <div className={`font-openSans font-bold tracking-[-1px] text-xl uppercase text-white inline-block relative ${longText ? 'h-[54px] leading-tight' : 'h-[24px] mt-[5px]'}`}>
                            {title}
                        </div>
                    </div>
                </a>
            </li>
    )
  }

  return (
        <>
            <div className={`cabaret-page-title ${noTitle ? '!hidden' : 'pb-12'}`}>
                <h1>Infos</h1>
            </div>
            <nav className='cabaret-infos-navigation pb-12'>
                <ul className='flex justify-center items-center flex-wrap max-w-[1000px] mx-auto' id="info_content">

                    { getNavButton('Les Prix', '/cabaret/infos/lesprix#info_content', lesPrixImage, 'Logo Prix') }
                    { getNavButton('Règlements', '/cabaret/infos/reglements#info_content', reglementsImage, 'Logo Règlements') }
                    { getNavButton('Inscriptions', '/cabaret/infos/inscription#info_content', inscriptionImage, 'Logo Inscription') }
                    { getNavButton('Hébergement', '/cabaret/infos/hebergements#info_content', hebergementsImage, 'Logo Hébergements') }
                    { getNavButton('Porte-Parole', '/cabaret/infos/porteparole#info_content', porteParoleImage, 'Logo Porte Parole') }
                    { getNavButton('Partenaires', '/cabaret/infos/partenaires#info_content', partenairesImage, 'Logo Partenaires') }
                    { getNavButton('Gagnant.es 2024', '/cabaret/infos/gagnants#info_content', gagnantsImage, 'Logo Gagnants') }

                </ul>
            </nav>
        </>
  )
}

export default CabaretInfosNavigation
